import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  class: "card",
  "data-aos": "zoom-in"
}
const _hoisted_2 = { class: "list-group-item h5" }
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[4] || (_cache[4] = _createElementVNode("i", { class: "bi bi-search text-primary h3 mx-4 mt-4 position-absolute mt-1" }, null, -1 /* HOISTED */)),
    _withDirectives(_createElementVNode("input", {
      class: "form-control form-control-solid w-250px ps-14 border border-primary mb-4",
      type: "text",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.searchquery) = $event)),
      onInput: _cache[1] || (_cache[1] = ($event: any) => ($setup.filtereddata())),
      placeholder: "Search title.."
    }, null, 544 /* NEED_HYDRATION, NEED_PATCH */), [
      [_vModelText, $setup.searchquery]
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createCommentVNode(" <div class=\"card-header\">\r\n      <span class=\"h3 mt-4\">\r\n    <a href=\"#\" class=\"btn btn-success px-3 py-2 fs-5\">UPLOAD .csv <i class=\"bi bi-plus-square text-white h8 mx-2 align-middle\"></i></a> \r\n </span>\r\n  </div> "),
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "card-header" }, [
        _createElementVNode("span", { class: "h3 text-primary mt-4" }, [
          _createTextVNode("Credential Sheets ("),
          _createElementVNode("i", { class: "bi bi-filetype-csv text-primary h3 mx-2" }),
          _createTextVNode(") "),
          _createElementVNode("i", { class: "bi bi-cloud-arrow-down-fill text-primary h2 mx-2 align-middle" })
        ])
      ], -1 /* HOISTED */)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.urls, (value, index) => {
        return (_openBlock(), _createElementBlock("ul", {
          class: "list-group list-group-flush",
          key: index
        }, [
          _createElementVNode("li", _hoisted_2, [
            _cache[2] || (_cache[2] = _createElementVNode("i", { class: "bi bi-filetype-csv text-primary h3 mx-2" }, null, -1 /* HOISTED */)),
            _createElementVNode("span", null, _toDisplayString(value), 1 /* TEXT */),
            _createElementVNode("a", {
              href: `https://production.brighterbins.com/csv/download/${value}`,
              download: "",
              class: "btn btn-primary px-3 py-2"
            }, "Download ", 8 /* PROPS */, _hoisted_3)
          ])
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ], 64 /* STABLE_FRAGMENT */))
}